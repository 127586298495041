//Install
import React, { Component } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import Menu from "./Footer/Menu";
//Install
import Social from "./Footer/Social";
//Install
import Submenu from "./Footer/Submenu";
//Install
import Copyright from "./Footer/Copyright";
//Install
import Information from "./Footer/Information";

//Element
class Footer extends Component {
    //Element
    render() {
        //Element
        const Content = React.createElement( "footer", { className: "float-start w-100 p-0 m-0 f09459" },
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 pt-4 m-0 mt-5 a87705" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:7 },
                            //Element
                            React.createElement( Menu, {} )
                        ),
                        //Element
                        React.createElement( Col, { md:2 },
                            //Element
                            React.createElement( Submenu, {} )
                        ),
                        //Element
                        React.createElement( Col, { md:3 },
                            //Element
                            React.createElement( Social, {} ),
                            //Element
                            React.createElement( Information, {} )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 guv280" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( Copyright, { ID: "0", Title: "© 2024 Telif Hakkı Erşah Gıda Tüm Hakları Saklıdır." } )
                        )
                    )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Footer;