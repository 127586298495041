//Install
import React, { Component } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import { Swiper, SwiperSlide } from "swiper/react";
//Install
import { Navigation, Pagination, Autoplay } from "swiper/modules";
//Install
import Media from "../../Content/Media";
//Install
import Item from "../../Shortcode/Product/Item";
//Install
import Headline from "../../Content/Headline";

//Element
class Default extends Component {
    //Element
    componentDidMount(){
        //Element
        document.title = "Erşah Gıda : Ürünler - Ersahgida.com.tr"
        //Element
        document.getElementsByTagName( "meta" )["description"].content = ""
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( "main", { className: "float-start w-100 p-0 m-0 nf9678" },
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 hc7830" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 s85k6d" },
                                //Element
                                React.createElement( "h1", { className: "d-flex w-100 justify-content-start align-items-center p-0 m-0 mt-4 position-relative x6gb52" },
                                    //Element
                                    "Oret Et Ürünleri"
                                )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 e807we" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:3 },
                            //Element
                            React.createElement( Item, { ID: "0", Title: "Piliç Baharatlı Salam", Photo: "016.jpg", Target: "/" } )
                        ),
                        //Element
                        React.createElement( Col, { md:3 },
                            //Element
                            React.createElement( Item, { ID: "0", Title: "Piliç Macar Salam", Photo: "019.jpg", Target: "/" } )
                        ),
                        //Element
                        React.createElement( Col, { md:3 },
                            //Element
                            React.createElement( Item, { ID: "0", Title: "Piliç Tost Jambon", Photo: "020.jpg", Target: "/" } )
                        ),
                        //Element
                        React.createElement( Col, { md:3 },
                            //Element
                            React.createElement( Item, { ID: "0", Title: "Piliç Sosis", Photo: "028.jpg", Target: "/" } )
                        ),
                        //Element
                        React.createElement( Col, { md:3 },
                            //Element
                            React.createElement( Item, { ID: "0", Title: "Piliç Zeytinli Salam", Photo: "033.jpg", Target: "/" } )
                        ),
                        //Element
                        React.createElement( Col, { md:3 },
                            //Element
                            React.createElement( Item, { ID: "0", Title: "Piliç Vakumlu Baton", Photo: "034.jpg", Target: "/" } )
                        ),
                        //Element
                        React.createElement( Col, { md:3 },
                            //Element
                            React.createElement( Item, { ID: "0", Title: "Piliç Fıstıklı Salam", Photo: "037.jpg", Target: "/" } )
                        ),
                        //Element
                        React.createElement( Col, { md:3 },
                            //Element
                            React.createElement( Item, { ID: "0", Title: "Dondurulmuş Kolisi", Photo: "038.jpg", Target: "/" } )
                        ),
                        //Element
                        React.createElement( Col, { md:3 },
                            //Element
                            React.createElement( Item, { ID: "0", Title: "Piliç Sandviç Salam", Photo: "040.jpg", Target: "/" } )
                        ),
                        //Element
                        React.createElement( Col, { md:3 },
                            //Element
                            React.createElement( Item, { ID: "0", Title: "Dilimli Kolisi", Photo: "048.jpg", Target: "/" } )
                        ),
                        //Element
                        React.createElement( Col, { md:3 },
                            //Element
                            React.createElement( Item, { ID: "0", Title: "Piliç Sandviç Salam (1500gr)", Photo: "050.jpg", Target: "/" } )
                        ),
                        //Element
                        React.createElement( Col, { md:3 },
                            //Element
                            React.createElement( Item, { ID: "0", Title: "Dilimli Üst Folyo", Photo: "051.jpg", Target: "/" } )
                        ),
                        //Element
                        React.createElement( Col, { md:3 },
                            //Element
                            React.createElement( Item, { ID: "0", Title: "Piliç Kokteyl Sosis", Photo: "053.jpg", Target: "/" } )
                        ),
                        //Element
                        React.createElement( Col, { md:3 },
                            //Element
                            React.createElement( Item, { ID: "0", Title: "Piliç Dilimli Salam", Photo: "054.jpg", Target: "/" } )
                        ),
                        //Element
                        React.createElement( Col, { md:3 },
                            //Element
                            React.createElement( Item, { ID: "0", Title: "Piliç Emülsüfiye Sosis", Photo: "056.jpg", Target: "/" } )
                        ),
                        //Element
                        React.createElement( Col, { md:3 },
                            //Element
                            React.createElement( Item, { ID: "0", Title: "Piliç Okul Sosis Dilimli", Photo: "057.jpg", Target: "/" } )
                        ),
                        //Element
                        React.createElement( Col, { md:3 },
                            //Element
                            React.createElement( Item, { ID: "0", Title: "Baharatlı Salam", Photo: "058.jpg", Target: "/" } )
                        ),
                        //Element
                        React.createElement( Col, { md:3 },
                            //Element
                            React.createElement( Item, { ID: "0", Title: "Piliç Sosis Otel", Photo: "059.jpg", Target: "/" } )
                        ),
                        //Element
                        React.createElement( Col, { md:3 },
                            //Element
                            React.createElement( Item, { ID: "0", Title: "Piliç Sosis", Photo: "060.jpg", Target: "/" } )
                        ),
                        //Element
                        React.createElement( Col, { md:3 },
                            //Element
                            React.createElement( Item, { ID: "0", Title: "Piliç Sosis Okul", Photo: "061.jpg", Target: "/" } )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 hc7830" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 s85k6d" },
                                //Element
                                React.createElement( "h2", { className: "d-flex w-100 justify-content-start align-items-center p-0 m-0 mt-5 position-relative x6gb52" },
                                    //Element
                                    "Şahköy Tavuk Ürünleri"
                                )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 e807we" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:3 },
                            //Element
                            React.createElement( Item, { ID: "0", Title: "", Photo: "014.png", Target: "/" } )
                        ),
                        //Element
                        React.createElement( Col, { md:3 },
                            //Element
                            React.createElement( Item, { ID: "0", Title: "", Photo: "014.png", Target: "/" } )
                        ),
                        //Element
                        React.createElement( Col, { md:3 },
                            //Element
                            React.createElement( Item, { ID: "0", Title: "", Photo: "014.png", Target: "/" } )
                        ),
                        //Element
                        React.createElement( Col, { md:3 },
                            //Element
                            React.createElement( Item, { ID: "0", Title: "", Photo: "014.png", Target: "/" } )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 hc7830" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 s85k6d" },
                                //Element
                                React.createElement( "h2", { className: "d-flex w-100 justify-content-start align-items-center p-0 m-0 mt-4 position-relative x6gb52" },
                                    //Element
                                    "Zeyno'nun Çiftliği Peynir Ürünleri"
                                )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 e807we" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:3 },
                            //Element
                            React.createElement( Item, { ID: "0", Title: "Mozzarella Peyniri", Photo: "014.png", Target: "/" } )
                        ),
                        //Element
                        React.createElement( Col, { md:3 },
                            //Element
                            React.createElement( Item, { ID: "0", Title: "Künefe Peyniri", Photo: "014.png", Target: "/" } )
                        ),
                        //Element
                        React.createElement( Col, { md:3 },
                            //Element
                            React.createElement( Item, { ID: "0", Title: "Börek Peyniri", Photo: "014.png", Target: "/" } )
                        ),
                        //Element
                        React.createElement( Col, { md:3 },
                            //Element
                            React.createElement( Item, { ID: "0", Title: "Tereyağı", Photo: "014.png", Target: "/" } )
                        )
                    )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Default;