//Install
import React, { Component } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import { Swiper, SwiperSlide } from "swiper/react";
//Install
import { Navigation, Pagination, Autoplay } from "swiper/modules";
//Install
import Headline from "./Content/Headline";
//Install
import Media from "./Content/Media";
//Install
import Product from "./Content/Product";
//Install
import News from "./Content/News";
//Install
import Location from "./Content/Location";
//Install
import Form from "./Content/Form";

//Element
class Main extends Component {
    //Element
    componentDidMount(){
        //Element
        document.title = "Erşah Gıda - Ersahgida.com.tr"
        //Element
        document.getElementsByTagName( "meta" )["description"].content = ""
    }
    //Element
    render() {
        //Element
        const Slider = {
            //Element
            spaceBetween    : "0",
            //Element
            slidesPerView   : "2",
            //Element
            autoplay        : {
                //Element
                delay       : 5000
            },
            //Element
            loop            : true,
            //Element
            freeMode        : true,
            //Element
            breakpoints     : {
                //Element
                320:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                360:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                480:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                504:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                600:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                768:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                1024:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                1280:{
                    //Element
                    slidesPerView : 2
                }
            },
            //Element
            modules         : [ Navigation, Pagination, Autoplay ]
        }
        //Element
        const Products = {
            //Element
            spaceBetween    : "12",
            //Element
            slidesPerView   : "4",
            //Element
            autoplay        : {
                //Element
                delay       : 5000
            },
            //Element
            loop            : true,
            //Element
            freeMode        : true,
            //Element
            breakpoints     : {
                //Element
                320:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                360:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                480:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                504:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                600:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                768:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                1024:{
                    //Element
                    slidesPerView : 3
                },
                //Element
                1280:{
                    //Element
                    slidesPerView : 4
                }
            },
            //Element
            modules         : [ Navigation, Pagination, Autoplay ]
        }
        //Element
        const Content = React.createElement( "main", { className: "float-start w-100 p-0 m-0 nf9678" },
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 position-relative r34t6z" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:6 },
                            //Element
                            React.createElement( Headline, { ID: "0", Title: "Özünde Lezzet Var", Video:"010.mp4", Target: "/" } )
                        ),
                        //Element
                        React.createElement( Col, { md:6 },
                            //Element
                            React.createElement( Headline, { ID: "0", Title: "Özünde Lezzet Var", Video:"002.mp4", Target: "/" } )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 t0e05c" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:3 },
                            //Element
                            React.createElement( "div", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 mt-5 nz59r9" },
                                //Element
                                React.createElement( "img", { alt: "Oret", title: "Oret", src: "https://media.ersahgida.com.tr/brand/010.png", width: "145", height: "auto" } )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:5 },
                            //Element
                            React.createElement( Media, { ID: "0", Title: "", Video: "007.mp4", Target: "/" } )
                        ),
                        //Element
                        React.createElement( Col, { md:4 },
                            //Element
                            React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-center p-0 m-0 mt-5 nz59r9" },
                                //Element
                                React.createElement( "h2", { className: "float-start w-auto p-0 m-0 lh-1 text-center g69l8n" },
                                    //Element
                                    "Lezzetin ",
                                    //Element
                                    React.createElement( "strong", {}, "Kalbinden" ),
                                    //Element
                                    " Gelenler"
                                ),
                                //Element
                                React.createElement( "p", { className: "float-start w-auto p-0 m-0 mt-3 text-center bb198o" },
                                    //Element
                                    "Özenle hazırlanan, eşsiz tatlarla dolu yemeklerimizle damağınıza",
                                    //Element
                                    React.createElement( "br", {} ),
                                    //Element
                                    "unutulmaz bir şölen yaşatıyoruz. Kaliteden ödün vermeden,",
                                    //Element
                                    React.createElement( "br", {} ),
                                    //Element
                                    "lezzetli ve özgün seçenekler sunuyor."
                                ),
                                //Element
                                React.createElement( "a", { className: "float-start w-auto p-0 m-0 mt-3 lh-1 text-center bb198o", href: "/markalar" },
                                    //Element
                                    React.createElement( "u", {},
                                        //Element
                                        "Hemen Bakın"
                                    )
                                )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 hc7830" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md: 12 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 s85k6d" },
                                //Element
                                React.createElement( "h2", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 mt-5 position-relative o236ly" },
                                    //Element
                                    "Oret Ürünleri"
                                )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 e807we" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md: 12 },
                            //Element
                            React.createElement( Swiper, { className: "float-start w-100 position-relative overflow-hidden", ...Products },
                                //Element
                                React.createElement( SwiperSlide, {},
                                    //Element
                                    React.createElement( Product, { ID: "0", Title: "Piliç Baharatlı Salam", Photo: "016.jpg", Target: "/" } )
                                ),
                                //Element
                                React.createElement( SwiperSlide, {},
                                    //Element
                                    React.createElement( Product, { ID: "0", Title: "", Photo: "017.jpg", Target: "/" } )
                                ),
                                //Element
                                React.createElement( SwiperSlide, {},
                                    //Element
                                    React.createElement( Product, { ID: "0", Title: "", Photo: "018.jpg", Target: "/" } )
                                ),
                                //Element
                                React.createElement( SwiperSlide, {},
                                    //Element
                                    React.createElement( Product, { ID: "0", Title: "Piliç Macar Salam", Photo: "019.jpg", Target: "/" } )
                                ),
                                //Element
                                React.createElement( SwiperSlide, {},
                                    //Element
                                    React.createElement( Product, { ID: "0", Title: "Piliç Tost Jambon", Photo: "020.jpg", Target: "/" } )
                                ),
                                //Element
                                React.createElement( SwiperSlide, {},
                                    //Element
                                    React.createElement( Product, { ID: "0", Title: "", Photo: "021.jpg", Target: "/" } )
                                ),
                                //Element
                                React.createElement( SwiperSlide, {},
                                    //Element
                                    React.createElement( Product, { ID: "0", Title: "", Photo: "022.jpg", Target: "/" } )
                                ),
                                //Element
                                React.createElement( SwiperSlide, {},
                                    //Element
                                    React.createElement( Product, { ID: "0", Title: "", Photo: "023.jpg", Target: "/" } )
                                ),
                                //Element
                                React.createElement( SwiperSlide, {},
                                    //Element
                                    React.createElement( Product, { ID: "0", Title: "", Photo: "024.jpg", Target: "/" } )
                                ),
                                //Element
                                React.createElement( SwiperSlide, {},
                                    //Element
                                    React.createElement( Product, { ID: "0", Title: "", Photo: "025.jpg", Target: "/" } )
                                ),
                                //Element
                                React.createElement( SwiperSlide, {},
                                    //Element
                                    React.createElement( Product, { ID: "0", Title: "", Photo: "026.jpg", Target: "/" } )
                                ),
                                //Element
                                React.createElement( SwiperSlide, {},
                                    //Element
                                    React.createElement( Product, { ID: "0", Title: "", Photo: "027.jpg", Target: "/" } )
                                ),
                                //Element
                                React.createElement( SwiperSlide, {},
                                    //Element
                                    React.createElement( Product, { ID: "0", Title: "Piliç Sosis", Photo: "028.jpg", Target: "/" } )
                                ),
                                //Element
                                React.createElement( SwiperSlide, {},
                                    //Element
                                    React.createElement( Product, { ID: "0", Title: "", Photo: "029.jpg", Target: "/" } )
                                ),
                                //Element
                                React.createElement( SwiperSlide, {},
                                    //Element
                                    React.createElement( Product, { ID: "0", Title: "", Photo: "030.jpg", Target: "/" } )
                                ),
                                //Element
                                React.createElement( SwiperSlide, {},
                                    //Element
                                    React.createElement( Product, { ID: "0", Title: "", Photo: "031.jpg", Target: "/" } )
                                ),
                                //Element
                                React.createElement( SwiperSlide, {},
                                    //Element
                                    React.createElement( Product, { ID: "0", Title: "", Photo: "032.jpg", Target: "/" } )
                                ),
                                //Element
                                React.createElement( SwiperSlide, {},
                                    //Element
                                    React.createElement( Product, { ID: "0", Title: "Piliç Zeytinli Salam", Photo: "033.jpg", Target: "/" } )
                                ),
                                //Element
                                React.createElement( SwiperSlide, {},
                                    //Element
                                    React.createElement( Product, { ID: "0", Title: "Piliç Vakumlu Baton", Photo: "034.jpg", Target: "/" } )
                                ),
                                //Element
                                React.createElement( SwiperSlide, {},
                                    //Element
                                    React.createElement( Product, { ID: "0", Title: "", Photo: "035.jpg", Target: "/" } )
                                ),
                                //Element
                                React.createElement( SwiperSlide, {},
                                    //Element
                                    React.createElement( Product, { ID: "0", Title: "", Photo: "036.jpg", Target: "/" } )
                                ),
                                //Element
                                React.createElement( SwiperSlide, {},
                                    //Element
                                    React.createElement( Product, { ID: "0", Title: "Piliç Fıstıklı Salam", Photo: "037.jpg", Target: "/" } )
                                ),
                                //Element
                                React.createElement( SwiperSlide, {},
                                    //Element
                                    React.createElement( Product, { ID: "0", Title: "Dondurulmuş Kolisi", Photo: "038.jpg", Target: "/" } )
                                ),
                                //Element
                                React.createElement( SwiperSlide, {},
                                    //Element
                                    React.createElement( Product, { ID: "0", Title: "", Photo: "039.jpg", Target: "/" } )
                                ),
                                //Element
                                React.createElement( SwiperSlide, {},
                                    //Element
                                    React.createElement( Product, { ID: "0", Title: "Piliç Sandviç Salam", Photo: "040.jpg", Target: "/" } )
                                ),
                                //Element
                                React.createElement( SwiperSlide, {},
                                    //Element
                                    React.createElement( Product, { ID: "0", Title: "", Photo: "041.jpg", Target: "/" } )
                                ),
                                //Element
                                React.createElement( SwiperSlide, {},
                                    //Element
                                    React.createElement( Product, { ID: "0", Title: "", Photo: "042.jpg", Target: "/" } )
                                ),
                                //Element
                                React.createElement( SwiperSlide, {},
                                    //Element
                                    React.createElement( Product, { ID: "0", Title: "", Photo: "043.jpg", Target: "/" } )
                                ),
                                //Element
                                React.createElement( SwiperSlide, {},
                                    //Element
                                    React.createElement( Product, { ID: "0", Title: "", Photo: "044.jpg", Target: "/" } )
                                ),
                                //Element
                                React.createElement( SwiperSlide, {},
                                    //Element
                                    React.createElement( Product, { ID: "0", Title: "", Photo: "045.jpg", Target: "/" } )
                                ),
                                //Element
                                React.createElement( SwiperSlide, {},
                                    //Element
                                    React.createElement( Product, { ID: "0", Title: "", Photo: "046.jpg", Target: "/" } )
                                ),
                                //Element
                                React.createElement( SwiperSlide, {},
                                    //Element
                                    React.createElement( Product, { ID: "0", Title: "", Photo: "047.jpg", Target: "/" } )
                                ),
                                //Element
                                React.createElement( SwiperSlide, {},
                                    //Element
                                    React.createElement( Product, { ID: "0", Title: "Dilimli Kolisi", Photo: "048.jpg", Target: "/" } )
                                ),
                                //Element
                                React.createElement( SwiperSlide, {},
                                    //Element
                                    React.createElement( Product, { ID: "0", Title: "", Photo: "049.jpg", Target: "/" } )
                                ),
                                //Element
                                React.createElement( SwiperSlide, {},
                                    //Element
                                    React.createElement( Product, { ID: "0", Title: "Piliç Sandviç Salam (1500gr)", Photo: "050.jpg", Target: "/" } )
                                ),
                                //Element
                                React.createElement( SwiperSlide, {},
                                    //Element
                                    React.createElement( Product, { ID: "0", Title: "Dilimli Üst Folyo", Photo: "051.jpg", Target: "/" } )
                                ),
                                //Element
                                React.createElement( SwiperSlide, {},
                                    //Element
                                    React.createElement( Product, { ID: "0", Title: "", Photo: "052.jpg", Target: "/" } )
                                ),
                                //Element
                                React.createElement( SwiperSlide, {},
                                    //Element
                                    React.createElement( Product, { ID: "0", Title: "Piliç Kokteyl Sosis", Photo: "053.jpg", Target: "/" } )
                                ),
                                //Element
                                React.createElement( SwiperSlide, {},
                                    //Element
                                    React.createElement( Product, { ID: "0", Title: "Piliç Dilimli Salam", Photo: "054.jpg", Target: "/" } )
                                ),
                                //Element
                                React.createElement( SwiperSlide, {},
                                    //Element
                                    React.createElement( Product, { ID: "0", Title: "", Photo: "055.jpg", Target: "/" } )
                                ),
                                //Element
                                React.createElement( SwiperSlide, {},
                                    //Element
                                    React.createElement( Product, { ID: "0", Title: "Piliç Emülsüfiye Sosis", Photo: "056.jpg", Target: "/" } )
                                ),
                                //Element
                                React.createElement( SwiperSlide, {},
                                    //Element
                                    React.createElement( Product, { ID: "0", Title: "Piliç Okul Sosis Dilimli", Photo: "057.jpg", Target: "/" } )
                                ),
                                //Element
                                React.createElement( SwiperSlide, {},
                                    //Element
                                    React.createElement( Product, { ID: "0", Title: "Baharatlı Salam", Photo: "058.jpg", Target: "/" } )
                                ),
                                //Element
                                React.createElement( SwiperSlide, {},
                                    //Element
                                    React.createElement( Product, { ID: "0", Title: "Piliç Sosis Otel", Photo: "059.jpg", Target: "/" } )
                                ),
                                //Element
                                React.createElement( SwiperSlide, {},
                                    //Element
                                    React.createElement( Product, { ID: "0", Title: "Piliç Sosis", Photo: "060.jpg", Target: "/" } )
                                ),
                                //Element
                                React.createElement( SwiperSlide, {},
                                    //Element
                                    React.createElement( Product, { ID: "0", Title: "Piliç Sosis Okul", Photo: "061.jpg", Target: "/" } )
                                )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 t0e05c" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:3 },
                            //Element
                            React.createElement( "div", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 mt-5 nz59r9" },
                                //Element
                                React.createElement( "img", { alt: "Şahköy", title: "Şahköy", src: "https://media.ersahgida.com.tr/brand/008.png", width: "200", height: "137" } )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:5 },
                            //Element
                            React.createElement( Media, { ID: "0", Title: "", Video: "006.mp4", Target: "/" } )
                        ),
                        //Element
                        React.createElement( Col, { md:4 },
                            //Element
                            React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-center p-0 m-0 mt-5 nz59r9" },
                                //Element
                                React.createElement( "h2", { className: "float-start w-auto p-0 m-0 lh-1 text-center g69l8n" },
                                    //Element
                                    "Protein ve Kolajenle Dolu ",
                                    //Element
                                    React.createElement( "strong", {}, "#GezenTavuk" )
                                ),
                                //Element
                                React.createElement( "p", { className: "float-start w-auto p-0 m-0 mt-3 text-center bb198o" },
                                    //Element
                                    "Doğadan özenle seçilen kıymetli besinlerle beslenen Tavuklar,",
                                    //Element
                                    React.createElement( "br", {} ),
                                    //Element
                                    "sağlığınıza katkı sağlayan lezzetiyle sofralarınızın",
                                    //Element
                                    React.createElement( "br", {} ),
                                    //Element
                                    "vazgeçilmez bir parçası olacak."
                                ),
                                //Element
                                React.createElement( "a", { className: "float-start w-auto p-0 m-0 mt-3 lh-1 text-center bb198o", href: "/markalar" },
                                    //Element
                                    React.createElement( "u", {},
                                        //Element
                                        "Hemen Bakın"
                                    )
                                )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 hc7830" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md: 12 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 s85k6d" },
                                //Element
                                React.createElement( "h3", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 mt-5 position-relative o236ly" },
                                    //Element
                                    "Şahköy Tavuğu"
                                )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 mt-5 e807we gZ959C" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:3 },
                            //Element
                            React.createElement( News, { ID: "0", Title: "Nitelikli Kümeslerde Büyür.", Summary: "Nitelikli kümeslerde büyüyen tavuklar, sağlıklı ve konforlu bir ortamda yetiştirilir.", Photo: "009.jpg", Target: "/" } )
                        ),
                        //Element
                        React.createElement( Col, { md:3 },
                            //Element
                            React.createElement( News, { ID: "0", Title: "Doğada 500 Gün.", Summary: "Doğada en az 500 gün serbest dolaşan tavuklar, besleyici, sağlıklı ve lezzetli doğal gıdalar sağlar.", Photo: "010.jpg", Target: "/" } )
                        ),
                        //Element
                        React.createElement( Col, { md:3 },
                            //Element
                            React.createElement( News, { ID: "0", Title: "Besin Değerlerini Korur.", Summary: "Hijyenik bir şekilde paketlenen ürünler, -45°C'de dondurularak bakteri ve mikroplardan korunur.", Photo: "011.jpg", Target: "/" } )
                        ),
                        //Element
                        React.createElement( Col, { md:3 },
                            //Element
                            React.createElement( News, { ID: "0", Title: "Donuk Olarak Satışa Sunulur.", Summary: "Tavuk, doğru muhafaza edilmediğinde hızla bozulabilen ve sağlığa zarar verebilecek bir besindir.", Photo: "012.jpg", Target: "/" } )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 t0e05c" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:3 },
                            //Element
                            React.createElement( "div", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 mt-5 nz59r9" },
                                //Element
                                React.createElement( "img", { alt: "Zeyno'nun Çiftliği", title: "Zeyno'nun Çiftliği", src: "https://media.ersahgida.com.tr/brand/007.png", width: "200", height: "135" } )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:5 },
                            //Element
                            React.createElement( Media, { ID: "0", Title: "", Video: "005.mp4", Target: "/" } )
                        ),
                        //Element
                        React.createElement( Col, { md:4 },
                            //Element
                            React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-center p-0 m-0 mt-5 nz59r9" },
                                //Element
                                React.createElement( "h2", { className: "float-start w-auto p-0 m-0 lh-1 text-center g69l8n" },
                                    //Element
                                    "Ustaların Tercihi: Pizza ⅋ Künefe ",
                                    //Element
                                    React.createElement( "strong", {}, "#Peyniri" )
                                ),
                                //Element
                                React.createElement( "p", { className: "float-start w-auto p-0 m-0 mt-3 text-center bb198o" },
                                    //Element
                                    "Yapılan her pizza, künefe ve pide herkesin damak zevkine hitap eden lezzetlerle dolu olacak. Sizde emeğinizin karşılığını",
                                    //Element
                                    React.createElement( "br", {} ),
                                    //Element
                                    "alarak, kazancınızın keyfini çıkarın."
                                ),
                                //Element
                                React.createElement( "a", { className: "float-start w-auto p-0 m-0 mt-3 lh-1 text-center bb198o", href: "/markalar" },
                                    //Element
                                    React.createElement( "u", {},
                                        //Element
                                        "Hemen Bakın"
                                    )
                                )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 hc7830" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 s85k6d" },
                                //Element
                                React.createElement( "h3", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 mt-5 position-relative o236ly" },
                                    //Element
                                    "Zeyno'nun Çiftliğinden"
                                )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 e807we" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( Swiper, { className: "float-start w-100 position-relative overflow-hidden", ...Products },
                                //Element
                                React.createElement( SwiperSlide, {},
                                    //Element
                                    React.createElement( Product, { ID: "0", Title: "Dana Dilim Sucuk", Photo: "013.png", Target: "/" } )
                                ),
                                //Element
                                React.createElement( SwiperSlide, {},
                                    //Element
                                    React.createElement( Product, { ID: "0", Title: "Dana Kangal Sucuk", Photo: "014.png", Target: "/" } )
                                ),
                                //Element
                                React.createElement( SwiperSlide, {},
                                    //Element
                                    React.createElement( Product, { ID: "0", Title: "Dana Baton Salam", Photo: "015.png", Target: "/" } )
                                ),
                                //Element
                                React.createElement( SwiperSlide, {},
                                    //Element
                                    React.createElement( Product, { ID: "0", Title: "Dana Kavurma", Photo: "013.png", Target: "/" } )
                                ),
                                //Element
                                React.createElement( SwiperSlide, {},
                                    //Element
                                    React.createElement( Product, { ID: "0", Title: "Piliç Dilimli Sucuk", Photo: "014.png", Target: "/" } )
                                ),
                                //Element
                                React.createElement( SwiperSlide, {},
                                    //Element
                                    React.createElement( Product, { ID: "0", Title: "Piliç Baton Salam", Photo: "015.png", Target: "/" } )
                                ),
                                //Element
                                React.createElement( SwiperSlide, {},
                                    //Element
                                    React.createElement( Product, { ID: "0", Title: "Piliç Çubuk Sosis", Photo: "013.png", Target: "/" } )
                                )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 jp1h55" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:6 },
                            //Element
                            React.createElement( Location, {} )
                        ),
                        //Element
                        React.createElement( Col, { md:6 },
                            //Element
                            React.createElement( Form, {} )
                        )
                    )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Main;