//Install
import React, { Component } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import SET from "./Header/SET";
//Install
import Menu from "./Header/Menu";
//Install
import Brand from "./Header/Brand";
//Install
import Submenu from "./Header/Submenu";

//Element
class Header extends Component {
    //Element
    componentDidMount(){
        //Element
        window.addEventListener( "scroll", this.Sticky )
    }
    //Element
    Sticky = () => {
        //Element
        let Header = document.getElementsByClassName( "zf706j" )[0]
        //Element
        if( window.scrollY > 20 ){
            //Element
            Header.classList.add( "position-fixed", "z-3" )
        } else {
            //Element
            Header.classList.remove( "position-fixed" )
        }
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( "header", { className: "float-start w-100 p-0 m-0 zf706j" },
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 kj7730" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( Submenu, {} )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 m4jk22" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:3 },
                            //Element
                            React.createElement( Brand, { ID: "0", Title: "Erşah Gıda" } )
                        ),
                        //Element
                        React.createElement( Col, { md:6 },
                            //Element
                            React.createElement( Menu, {} )
                        ),
                        //Element
                        React.createElement( Col, { md:3 },
                            //Element
                            React.createElement( SET, {} )
                        )
                    )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Header;